.welcome {
    display: flex;
    justify-content: center;
    width: 100rem;
}

.welcome__photo {
    height: 28rem;
    width: 30rem;
}

.welcome__title {
    color: $dark-red;
    font-weight: 500;
    font-size: 6rem;
    padding-bottom: 20px;
}
