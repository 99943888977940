.modal_style {
  position: absolute;
  top: 50%;
  left: 30%;
  right: auto;
  bottom: auto;
  margin-right: -30%;
  transform: translate(-30%, -30%);
  background-color: $light-grey;
  border-color: $dark-blue;
  border-radius: 10px;
  border-style: solid;
  padding: 10px;
}

.modal_title {
  color: $dark-red;
  font-weight: 400;
  font-size: $font-size-large;
  padding-bottom: 5px;
}

.modal_content {
  padding-top: $xs-size;
  display: flex;
  align-items: center;
}

.modal_label {
  font-weight: 800;
  font-size: $font-size-small;
  width: 230px;
}
 
.modal_readonly_input {
  border: 1px solid #cacccd;
  font-size: $font-size-small;
  font-weight: 300;
  width: 300px;
  height: 18px;
  padding: $s-size;
  margin-left: 5px;
  margin-right: 25px;
  align-self: end;
}

.modal_number_input {
  border: 1px solid #cacccd;
  font-size: $font-size-small;
  font-weight: 300;
  width: 200px;
  height: 18px;
  padding: $s-size;
  margin-left: 5px;
  margin-right: 25px;
}

.modal-button--group {
  display: flex;
  justify-content: right;
  width: 400px;
  margin-top: 20px;
}

.modal-button {
  background-color: $dark-blue;
  color: white;
  border-radius: 5px;
  font-size: $font-size-small;
  padding: 4px;
  width: 80px;
  margin: 4px;
}

.modal-button:disabled {
  background-color: $med-grey;
}

.modal__select {
  @extend .select;
  width: 240px;
  height: 25px;
  margin-left: 5px
}

.modal__comments {
  @extend .text-input;
  font-family: 'Open Sans', sans-serif;
  font-size: $font-size-small;
  height: 10rem;
  width: 30rem;
  margin-left: 5px;
}