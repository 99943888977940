.text-input {
    border: 1px solid #cacccd;
    font-size: $font-size-medium;
    font-weight: 300;
    width: 120px;
    height: 40px;
    padding: $s-size;
}

.textarea__comments {
    @extend .text-input;
    font-family: 'Open Sans', sans-serif;
    font-size: $font-size-small;
    height: 15rem;
    width: 35rem;
    margin-left: 10px;
}

.text-input__error {
    @extend .text-input;
    border: 1px solid red;
}

.select {
    @extend .text-input;
    font-size: $font-size-small;
    width: 260px;
    height: 30px;
    padding: 1px;
}

.sales__select {
    @extend .select;
    width: 200px;
    height: 40px;
    padding: 5px;
    display: block;
}

.plant__select {
    @extend .select;
    width: 220px;
    height: 40px;
    padding: 5px;
    display: block;
}

.customer__select {
    @extend .select;
    width: 240px;
    height: 40px;
    padding: 5px;
    display: block;
}

.species__select {
    @extend .select;
    width: 120px;
    height: 25px;
}

.species__select_error {
    @extend .species__select;
    border: 2px solid red;
}

.msc__select {
    @extend .select;
    width: 120px;
    height: 25px;
}

.yes-no__select {
    @extend .select;
    width: 80px;
    height: 25px;
}

.product__select {
    @extend .select;
    width: 220px;
    height: 25px;
}

.brand__select {
    @extend .select;
    width: 60px;
    height: 25px;
}

.brand__select_error {
    @extend .brand__select;
    border: 2px solid red;
}

.product__select_error {
    @extend .product__select;
    border: 2px solid red;
}

.location__select {
    @extend .select;
    width: 180px;
    height: 25px;
}

.location__select_error {
    @extend .location__select;
    border: 2px solid red;
}

.currency__select {
    @extend .select;
    width: 80px;
    height: 40px;
    display: block;
}

.textarea {
    @extend .text-input;
    height: 10rem;
}

.formdata {
    display: flex;
    align-items: center;
}

.formdata__padding {
    @extend .formdata;
    padding-top: 1.5rem;
}
.formdata__label {
    padding-left: 1rem;
    padding-right: 1rem;
}

.text-input__counter {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 70px;
    height: 20px;
    padding: $s-size;
}

.text-input__customer {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 180px;
    height: 38px;
    padding: $s-size;
    margin-left: 5px;
    display: block;
}

.text-input__customer-add-small {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 120px;
    height: 20px;
    padding: $s-size;
    margin-left: 5px;
    display: block;
}

.text-input__customer-add-medium {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 220px;
    height: 20px;
    padding: $s-size;
    margin-left: 5px;
    display: block;
}

.text-input__customer-add-large {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 300px;
    height: 20px;
    padding: $s-size;
    margin-left: 5px;
    display: block;
}

.label_margin_right {
    margin-right: 10px;
}

.label_margin_left {
    margin-left: 20px;
}

.label_margin_top {
    margin-top: 20px;
    margin-bottom: 20px;
}

.label_margin_bottom {
    margin-bottom: 20px;
}

.style_block {
    display: block;
}

.text-input__time {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 130px;
    height: 20px;
    padding: $s-size;
}

.text-input__scale {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 80px;
    height: 20px;
    padding: $s-size;
}

.text-input__date {
    border: 1px solid #cacccd;
    font-size: $font-size-small;
    font-weight: 300;
    width: 160px;
    height: 20px;
    padding: $s-size;
}

.text-input__margin {
    margin-left: 10px;
}

.text-input__counter_error {
    @extend .text-input__counter;
    border: 2px solid red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
