* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    color: $dark-grey;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: $m-size;
    line-height: 1.6;
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

.is-active {
    color: red;
}

fieldset {
    border: 0
}
