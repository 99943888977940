.amplify-tabs {
    display: none;
}

.amplify-select,
.amplify-input,
.amplify-textarea {
    font-size: 14px !important;
}

.amplify-button {
    font-size: 16px !important;
}

.amplify-button[data-size='small'] {
    font-size: 14px !important;
    color: $dark-blue;
}

.amplify-button[data-variation='primary'] {
    background-color: $medium-blue;
}

.amplify-button[data-variation='primary']:hover {
    background-color: $dark-blue;
}