.menu {
    background: $light-grey;
}

.menu__content {
    background: $dark-blue;
    width: 300px;
    height: 80vh;
    flex: 0 1 300px;
}

.menu__title {
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    align-items: center;
    list-style: none;
    height: 50px;
    padding-top: 10px;
    color: $light-grey;
    font-size: $font-size-medium;
    font-weight: 400;
    min-width: 175px;
}

.menu__title__active {
    @extend .menu__title;
    color: $yellow;
}

.flex__container {
    display: flex;
}

.flex__report {
    flex: 1;
    padding: 0px 20px 0px 20px;
}
